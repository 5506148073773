import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'

export const Section = styled.section`
  button, a {
    height: 48px;
    @media ${device.tablet} {
      width: 178px;
    }
  }
`

import React, { useState } from 'react'
import * as S from './style'
import Layout from 'src/layouts/BaseLayout'
import pageContext from './pageContext.json'

import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import {
  Hero,
  AindaMaisVantagens,
  IndiqueAmigosTorcedores,
} from './sections/_index'

import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'

const AthleticoParanaense = () => {
  const [ sendDatalayerEvent ] = useDataLayer()
  const [ , setOpenModal ] = useState(false)
  const handleClick = (dataLayer: IDataLayerParams) => {
    sendDatalayerEvent(dataLayer)
  }

  return (
    <S.Wrapper>
      <Layout pageContext={pageContext} handleHeaderOpenAccount={() => setOpenModal(true)}>
        <Hero sendDataLayer={handleClick} />
        <AindaMaisVantagens sendDataLayer={handleClick} />
        <IndiqueAmigosTorcedores sendDataLayer={handleClick} />
      </Layout>
    </S.Wrapper>
  )
}

export default AthleticoParanaense

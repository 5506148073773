import { useStaticQuery, graphql } from 'gatsby'

const PageQuery = () => {
  const pageQuery = useStaticQuery(graphql`
    query {
      HeroBannerAthleticoParanaensePJ: imageSharp(fluid: { originalName: { regex: "/hero-cap-pj-banner-athletico-paranaense/" }}) {
        fluid(maxWidth: 504, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      LogoAthleticoParanaenseInter: imageSharp(fluid: { originalName: { regex: "/logo-athletico-paranaense-inter/" }}) {
        fluid(maxWidth: 228, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      BannerAindaMaisVantagensPJ: imageSharp(fluid: { originalName: { regex: "/banner-cap-pj-ainda-mais-vantagens/" }}) {
        fluid(maxWidth: 512, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
  `)

  return pageQuery
}

export default PageQuery

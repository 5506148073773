import React, { useState } from 'react'
import useDomReady from 'src/hooks/window/useDomReady'
import useWidth from 'src/hooks/window/useWidth'

import { Modal } from 'src/components/Modal'
import PopupWhatsapp from 'src/components/PopupWhatsapp'
import WhatsappBottomSheet from 'src/components/WhatsappBottomSheet'

import * as S from './styles'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'

interface IHeroProps {
  sendDataLayer: (dataLayer: IDataLayerParams) => void;
}

const IndiqueAmigosTorcedores = ({ sendDataLayer }: IHeroProps) => {
  const buttonTitle = 'Convocar um amigo'
  const whatsappMessage = 'Aproveite a parceria Inter + Athletico e venha simplificar a vida com uma Conta Digital gratuita, cartão sem anuidade e muuuito dinheiro de volta. Baixe o Super App: https://intergo.app/56aa8020'

  const [ isPopupOpen, setIsPopupOpen ] = useState(false)
  const [ bottomSheet, setBottomSheet ] = useState(false)

  const windowWidth = useWidth(300)
  const domReady = useDomReady()
  const WIDTH_MD = 768

  const handleClick = () => {
    sendDataLayer({
      section: 'dobra_5',
      section_name: 'Indique amigos torcedores e aproveite ainda mais a parceria Athletico + Inter!',
      element_action: 'click button',
      element_name: buttonTitle,
    })
    if (windowWidth >= WIDTH_MD) {
      setIsPopupOpen(!isPopupOpen)
    } else {
      setBottomSheet(!bottomSheet)
    }
  }

  const PopupExitPage = domReady && (
    <Modal isModalOpen={isPopupOpen} setIsModalOpen={setIsPopupOpen} locationToRender={document.body}>
      <PopupWhatsapp whatsappMessage={whatsappMessage} closeModal={() => setIsPopupOpen(false)} />
    </Modal>
  )

  const BottomSheetMobile = domReady && (
    <Modal isModalOpen={bottomSheet} setIsModalOpen={setBottomSheet} locationToRender={document.body}>
      <WhatsappBottomSheet whatsappMessage={whatsappMessage} />
    </Modal>
  )

  return (
    <S.Section className='py-5'>
      {PopupExitPage}
      {BottomSheetMobile}
      <div className='container h-100'>
        <div className='row h-100 align-content-lg-center'>
          <div className='col-12 text-center'>
            <S.DivBackgroundCampoFutebol className='d-lg-flex justify-content-lg-center align-items-lg-center mx-md-auto'>
              <div>
                <h2 className='fs-24 lh-30 fs-md-32 lh-md-40 fw-400 text-white mb-4'>Indique seus amigos empreendedores e <span className='d-lg-block'>mantenha a torcida Furacão unida!</span></h2>
                <p className='fs-18 lh-22 text-white mb-3'>Junte a torcida, fortaleça o seu time do coração e aproveite para <span className='d-lg-block'>simplificar a gestão financeira empresarial de todos, com transações</span> simplificadas e vantagens exclusivas que só quem é cliente Inter <span className='d-lg-block'>Empresas tem!</span></p>
                <div className='py-2'>
                  <a
                    title={buttonTitle}
                    className='btn btn-orange--extra btn--lg rounded-2 text-none text-md-center'
                    onClick={() => {
                      handleClick()
                      sendDataLayer({
                      section: 'dobra_4',
                      section_name: 'Indique seus amigos empreendedores e mantenha a torcida Furacão unida!',
                      element_action: 'click button',
                      element_name: buttonTitle,
                    })
                    }}
                  >
                    {buttonTitle}
                  </a>
                </div>
              </div>
            </S.DivBackgroundCampoFutebol>
          </div>
        </div>
      </div>
    </S.Section>
  )
}

export default IndiqueAmigosTorcedores

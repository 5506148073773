import React from 'react'
import Img from 'gatsby-image'

import usePageQuery from '../../pageQuery'

import { Section } from './styles'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'
import useUtms from 'src/hooks/useUtms'

interface IHeroProps {
  sendDataLayer: (dataLayer: IDataLayerParams) => void;
}

const AindaMaisVantagens = ({ sendDataLayer }: IHeroProps) => {
  const data = usePageQuery()
  const utmLink = useUtms({ link: `${process.env.OPEN_ACCOUNT_PJ}`, campanha: 'cap-pj' })

  return (
    <Section className='py-5 bg-grayscale--500'>
      <div className='container'>
        <div className='row align-items-lg-center'>
          <div className='col-12 col-lg-7 col-xl-6'>
            <h2 className='fs-32 lh-40 fs-md-40 lh-md-50 fw-400 text-white mb-0 mb-md-4'>Cartão Empresarial com função débito e crédito</h2>
            <div className='py-4 d-md-none'>
              <Img fluid={data.BannerAindaMaisVantagensPJ.fluid} alt='Cartão Athletico Paranaense' />
            </div>
            <p className='fs-18 lh-22 text-white pb-3'>O Cartão Inter Empresas Mastercard tem inúmeras vantagens. Sem taxas e sem anuidade, nosso cartão para o empreendedor athleticano, possui programa de pontos, cashback em compras, cartão de débito e ainda conta com um design exclusivo do Athletico Paranaense.</p>
            <div className='mt-4'>
              <a
                href={utmLink}
                target='_blank'
                rel='noreferrer'
                onClick={() => sendDataLayer({
                  section: 'dobra_3',
                  section_name: 'Aproveite benefícios exclusivos no Super App Inter',
                  element_action: 'click button',
                  element_name: 'Quero o meu cartão',
                  redirect_url: utmLink,
                })}
                title='Abrir conta'
                className='btn btn-orange--extra btn--lg rounded-2 text-none text-md-center'
              >
                Abrir conta
              </a>
            </div>
          </div>
          <div className='d-none d-lg-flex justify-content-lg-end col-lg-5 col-xl-6'>
            <Img fluid={data.BannerAindaMaisVantagensPJ.fluid} alt='Cartão Athletico Paranaense' />
          </div>
        </div>
      </div>
    </Section>
  )
}

export default AindaMaisVantagens

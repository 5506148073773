import React from 'react'
import Img from 'gatsby-image'

import usePageQuery from '../../pageQuery'

import DefaultCarousel from 'src/components/UI/Carousels/DefaultCarousel'

import * as S from './styles'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'

import CheckSuccess from '@interco/icons/build-v4/orangeds/MD/check-success'
import DepositByBoleto from '@interco/icons/build-v4/orangeds/MD/deposit-by-boleto'
import Profitability from '@interco/icons/build-v4/orangeds/MD/profitability'
import PixOutline from '@interco/icons/build-v4/orangeds/MD/pix-outline'
import Card from '@interco/icons/build-v4/orangeds/MD/card'
import Computer from '@interco/icons/build-v4/orangeds/MD/computer'
import Investments from '@interco/icons/build-v4/orangeds/MD/investments'
import Machine from '@interco/icons/build-v4/orangeds/MD/machine'
import { orange } from 'src/styles/colors'
import useUtms from 'src/hooks/useUtms'

interface IHeroProps {
  sendDataLayer: (dataLayer: IDataLayerParams) => void;
}

const Hero = ({ sendDataLayer }: IHeroProps) => {
  const data = usePageQuery()
  const utmLink = useUtms({ link: `${process.env.OPEN_ACCOUNT_PJ}`, campanha: 'cap-pj' })

  return (
    <S.Section className='py-5'>
      <div className='container mb-md-5'>
        <div className='row align-items-md-center'>
          <div className='col-12 col-md-4 col-lg-5 col-xl-6 mb-5 mb-md-0'>
            <div>
              <Img fluid={data.HeroBannerAthleticoParanaensePJ.fluid} alt='Ilustração cartão Athletico Paranaense' />
            </div>
          </div>
          <div className='col-12 col-md-8 col-lg-7 col-xl-6'>
            <h1 className='fs-32 lh-40 fs-lg-48 lh-lg-60 fw-400 text-white mb-4'>Conta Digital Inter Empresas: Vantagens exclusivas para o empreendedor Athleticano.</h1>
            <p className='fs-18 lh-22 fw-400 text-white mb-4'>A parceria Inter Empresas e Athletico Paranaense chegou para simplificar seu negócio. Com uma conta 100% digital, sem tarifas e gerenciada através de um Super App você tem tudo o que precisa para superar os desafios de empreender.</p>
            <div>
              <a
                href={utmLink}
                onClick={() => sendDataLayer({
                  section: 'dobra_2',
                  section_name: 'Aproveite benefícios exclusivos no Super App Inter',
                  element_action: 'click button',
                  element_name: 'Quero o meu cartão',
                  redirect_url: utmLink,
                })}
                target='_blank'
                rel='noreferrer'
                title='Quero meu cartão'
                className='btn btn-orange--extra btn--lg rounded-2 text-none text-md-center'
              >
                Quero meu cartão
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className='container py-5'>
        <div className='row'>
          <div className='col-12 my-5 my-md-0'>
            <div>
              <Img fluid={data.LogoAthleticoParanaenseInter.fluid} alt='Logo Athletico Paranaense mais a logo do Inter' className='mx-auto' />
            </div>
          </div>
        </div>
      </div>
      <div className='container'>
        <div className='row align-items-md-center'>
          <div className='col-12 col-md-6 col-xl-4'>
            <h2 className='fs-32 lh-40 fs-lg-40 lh-lg-50 fw-400 text-white mb-4'>Simplifique seu negócio com o <span className='d-lg-block'>Super App Inter</span> Empresas</h2>
            <p className='fs-18 lh-22 fw-400 text-white mb-0 mb-md-4'>Furacão e Inter Empresas, a parceria que é um verdadeiro gol de placa! A colaboração que chegou para simplificar o seu negócio com vantagens exclusivas e ainda ajudar seu time do coração.</p>
            <div className='d-none d-md-block py-2'>
              <a
                href={utmLink}
                onClick={() => sendDataLayer({
                  section: 'dobra_2',
                  section_name: 'Aproveite benefícios exclusivos no Super App Inter',
                  element_action: 'click button',
                  element_name: 'Quero o meu cartão',
                  redirect_url: utmLink,
                })}
                target='_blank'
                rel='noreferrer'
                title='Quero o meu cartão'
                className='btn btn-orange--extra btn--lg rounded-2 text-none text-md-center'
              >
                Quero o meu cartão
              </a>
            </div>
          </div>
          <div className='col-12 col-md-6 col-xl-8'>
            <DefaultCarousel
              sm={{ items: 1, partialVisibilityGutter: 90 }}
              md={{ items: 2 }}
              lg={{ items: 2, partialVisibilityGutter: 30 }}
              xl={{ items: 4 }}
            >
              <div className='mr-4'>
                <S.Card key={data.icon} className='card mt-3 d-flex flex-column justify-content-between'>
                  <div className='mb-3'>
                    <CheckSuccess width={37} height={37} color={orange.extra} />
                  </div>
                  <div>
                    <h3 className='fs-16 lh-20 fw-400 text-grayscale--400'>Gestão de Cobranças Simplificada</h3>
                  </div>
                </S.Card>
                <S.Card key={data.icon} className='card mt-3 d-flex flex-column justify-content-between'>
                  <div className='mb-3'>
                    <Card width={37} height={37} color={orange.extra} />
                  </div>
                  <div>
                    <h3 className='fs-16 lh-20 fw-400 text-grayscale--400'>Cartão de crédito Inter Mastercard sem tarifas</h3>
                  </div>
                </S.Card>
              </div>
              <div className='mr-4'>
                <S.Card key={data.icon} className='card mt-3 d-flex flex-column justify-content-between'>
                  <div className='mb-3'>
                    <DepositByBoleto width={37} height={37} color={orange.extra} />
                  </div>
                  <div>
                    <h3 className='fs-16 lh-20 fw-400 text-grayscale--400'>Folha de pagamentos sem burocracias</h3>
                  </div>
                </S.Card>
                <S.Card key={data.icon} className='card mt-3 d-flex flex-column justify-content-between'>
                  <div className='mb-3'>
                    <Computer width={37} height={37} color={orange.extra} />
                  </div>
                  <div>
                    <h3 className='fs-16 lh-20 fw-400 text-grayscale--400'>Internet Banking com tudo o que sua empresa precisa</h3>
                  </div>
                </S.Card>
              </div>
              <div className='mr-4'>
                <S.Card key={data.icon} className='card mt-3 d-flex flex-column justify-content-between'>
                  <div className='mb-3'>
                    <Profitability width={37} height={37} color={orange.extra} />
                  </div>
                  <div>
                    <h3 className='fs-16 lh-20 fw-400 text-grayscale--400'>Integrações para você levar a gestão da sua empresa ao próximo nível</h3>
                  </div>
                </S.Card>
                <S.Card key={data.icon} className='card mt-3 d-flex flex-column justify-content-between'>
                  <div className='mb-3'>
                    <Investments width={37} height={37} color={orange.extra} />
                  </div>
                  <div>
                    <h3 className='fs-16 lh-20 fw-400 text-grayscale--400'>Investimentos para fazer seu caixa render mais</h3>
                  </div>
                </S.Card>
              </div>
              <div className='mr-4'>
                <S.Card key={data.icon} className='card mt-3 d-flex flex-column justify-content-between'>
                  <div className='mb-3'>
                    <PixOutline width={37} height={37} color={orange.extra} />
                  </div>
                  <div>
                    <h3 className='fs-16 lh-20 fw-400 text-grayscale--400'>Pix gratuito e ilimitado para simplificar suas transações</h3>
                  </div>
                </S.Card>
                <S.Card key={data.icon} className='card mt-3 d-flex flex-column justify-content-between'>
                  <div className='mb-3'>
                    <Machine width={37} height={37} color={orange.extra} />
                  </div>
                  <div>
                    <h3 className='fs-16 lh-20 fw-400 text-grayscale--400'>Maquininha com as melhores taxas do mercado</h3>
                  </div>
                </S.Card>
              </div>
            </DefaultCarousel>
            <div className='d-block d-md-none pt-4'>
              <a
                href={utmLink}
                onClick={() => sendDataLayer({
                  section: 'dobra_2',
                  section_name: 'Aproveite benefícios exclusivos no Super App Inter',
                  element_action: 'click button',
                  element_name: 'Quero o meu cartão',
                  redirect_url: utmLink,
                })}
                target='_blank'
                rel='noreferrer'
                title='Quero o meu cartão'
                className='btn btn-orange--extra btn--lg rounded-2 text-none text-md-center'
              >
                Quero o meu cartão
              </a>
            </div>
          </div>
        </div>
      </div>
    </S.Section>
  )
}

export default Hero
